import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { PaymentMethod, PaymentMethodType } from '../../../../../../../../_base-shared/models/Payment/PaymentMethod';
import { PaymentProcessorType } from '../../../../../../../../_base-shared/models/Payment/PaymentProcessor';
import { PaymentTerm } from '../../../../../../../../_base-shared/models/Payment/PaymentTerm';
import { PaymentPlanType } from '../../../../../../../../_base-shared/models/Product/PaymentPlanType';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../../environments/environment';
import { CardInfoModalComponent } from '../../../../../_shared/components/card-info-modal/card-info-modal.component';
import {
  ChangeAmountModalComponent
} from '../../../../../_shared/components/change-amount-modal/change-amount-modal.component';
import {
  ChangeDateModalComponent
} from '../../../../../_shared/components/change-date-modal/change-date-modal.component';
import {
  RequestPaymentModalComponent
} from '../../../../../_shared/components/request-payment-modal/request-payment-modal.component';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { DistributionService } from '../../../../distribution/distribution.service';
import { PaymentMethodService } from '../../../../payment/payment-method.service';
import { PaymentPlanTypeService } from '../../../../payment/payment-plan-type.service';
import { PaymentService } from '../../../../payment/payment.service';
import { UserService } from '../../../../user/user.service';
import { CaseService } from '../../../case.service';
import {
  CaseInstallmentAmountEditorComponent
} from '../case-installment-amount-editor/case-installment-amount-editor.component';
import {
  CasePaymentPlanGeneratorComponent
} from '../case-payment-plan-generator/case-payment-plan-generator.component';
import { PaymentStatusModalComponent } from '../payment-status-modal/payment-status-modal.component';
import { RecordPaymentModalComponent } from './record-payment-modal/record-payment-modal.component';
import {SplitTermComponent} from '../split-term/split-term.component';

@Component({
  selector:    'app-case-installment-list',
  templateUrl: './case-installment-list.component.html',
  styleUrls:   ['./case-installment-list.component.scss']
})
export class CaseInstallmentListComponent implements OnInit {
  @Input() componentType: 'distribution' | 'admin';
  @Input() case: Case;
  @Input() openRecordPaymentDialog: EventEmitter<any>;
  @Input() fetchInstalments: EventEmitter<any>;
  @Output() paymentRecorded: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('changeValueModal') valueModal: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public appEnv: string;
  public upcomingInstallments: Array<PaymentTerm> = [];
  public unpaidInstallments                       = [];
  public displayedColumns: Array<string>          = [];
  public installmentsPlanTypeControl              = new UntypedFormControl(['claim_fee', 'initial_payment', 'debt_plan', 'attorney_fee'], [Validators.required]);
  public dataSource: MatTableDataSource<PaymentTerm>;
  public dataSourceUnpaid: MatTableDataSource<PaymentTerm>;
  public recordForm: UntypedFormGroup;
  public totalResults: number;
  public showOnlyUnpaid                           = false;
  public sortOrder                                = null;
  public sortBy                                   = null;
  public filteredInstallmentsBalance              = {
    totalPaid:  0,
    totalToPay: 0
  };
  public debtPlanInstallmentsBalance              = {
    totalPaid:  0,
    totalToPay: 0
  };
  public additionalPlansInstallmentsBalance       = {
    totalPaid:  0,
    totalToPay: 0
  };
  public paginatorConfig                          = {
    pageIndex: 0,
    pageSize:  10,
    length:    1
  };
  public now                                      = new Date(new Date().setHours(0, 0, 0, 0));
  public days                                     = [];
  public isLoading                                = 0;
  public authUser: User;
  public paymentMethods: Array<PaymentMethod>;
  public paymentPlanTypes: Array<PaymentPlanType>;
  private forceDefaultProcessor                   = false;
  public selectionPayments                        = new SelectionModel<any>(true, []);
  public selectionPaymentsDisabled                = true;
  public onlyDebtPlanSelected                     = true;

  constructor(private userService: UserService,
              public dialog: MatDialog,
              private fb: UntypedFormBuilder,
              private globalEventsService: MainGlobalEventService,
              private paymentService: PaymentService,
              private distributionService: DistributionService,
              private caseService: CaseService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private paymentMethodService: PaymentMethodService,
              private paymentPlanTypeService: PaymentPlanTypeService
  ) {
    this.appEnv = environment.APP_ENV;
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser         = user;
      this.displayedColumns = this.getTableColumns(this.componentType);
      this.fetchInstallments(this.componentType);
      this.fetchPaymentPlanTypes(this.componentType);
      //  Get total amount of paid terms and total amount to pay
      this.case.terms.forEach(term => {
        this.filteredInstallmentsBalance.totalToPay += term.amount;
        this.filteredInstallmentsBalance.totalPaid += term.amount_paid;
        if (term.case_plan_id === this.case.debt_payment_plan.id) {
          this.debtPlanInstallmentsBalance.totalToPay += term.amount;
          this.debtPlanInstallmentsBalance.totalPaid += term.amount_paid;
        }
        if (term.case_plan_id !== this.case.debt_payment_plan.id) {
          this.additionalPlansInstallmentsBalance.totalToPay += term.amount;
          this.additionalPlansInstallmentsBalance.totalPaid += term.amount_paid;
        }
      });
    });

    this.installmentsPlanTypeControl.valueChanges.subscribe(value => {
      this.onlyDebtPlanSelected = (value.includes('debt_plan') && value.length === 1) ? true : false;
      console.log(this.onlyDebtPlanSelected);
      if (value.includes('debt_plan') || value.length === 0) {
        this.selectionPaymentsDisabled = true;
      } else {
        this.selectionPaymentsDisabled = false;
      }
      this.fetchInstallments(this.componentType);
      this.forceDefaultProcessor = value ? ! value.includes('debt_plan') : false;
    });

    //  Generate days array
    for (let i = 1; i <= 31; i++) {
      this.days.push(i);
    }

    this.selectionPayments.changed.subscribe(next => {
      this.checkIfDisabled(next);
    });
  }

  private checkIfDisabled(data) {
    data.added.forEach(payment => {
      if (payment.case_payment_plan.type.slug === 'debt_plan') {
        this.selectionPaymentsDisabled = true;
      } else {
        this.selectionPaymentsDisabled = false;
      }
    });
  }

  private fetchInstallments(componentType: 'admin' | 'distribution') {
    const data = {
      per_page:        this.paginatorConfig.pageSize,
      page:            this.paginatorConfig.pageIndex + 1,
      case_id:         this.case.id,
      select_all:      1,
      order:           this.sortOrder || 'asc',
      sort_by:         this.sortBy || 'term_date',
      plan_type_slugs: this.installmentsPlanTypeControl.value ? this.installmentsPlanTypeControl.value : []
    };

    const withRelations = [
      'case.client',
      'case.debt_payment_plan',
      'case.payment_status',
      'case_payment_plan.type',
      'case_payment_plan.case_creditor.creditor',
      'case_payment_plan.case_creditor.case_creditor_product',
      'parent_term'
    ];
    const observable    = componentType === 'admin' ?
                          this.paymentService.index(data, withRelations) :
                          this.distributionService.indexInstallments(this.case.uuid, data, withRelations);

    this.isLoading++;
    this.dataSource           = new MatTableDataSource<PaymentTerm>([]);
    this.upcomingInstallments = [];
    observable.pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => this.handleResponse(res),
        error => console.log(error)
      );
  }

  public saveTermId(installment: PaymentTerm) {
    const dialogRef = this.dialog.open(ChangeDateModalComponent, {
      width: '50%',
      data:  {
        term: installment
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchInstallments(this.componentType);
      }
    });
  }

  showHideUnpaid($event) {
    //  Reset paginator to first page
    this.paginator.firstPage();
    if ($event.checked) {
      this.paginatorConfig.length = this.unpaidInstallments.length;
    } else {
      this.paginatorConfig.length = this.upcomingInstallments.length;
    }
    this.showOnlyUnpaid = $event.checked;
  }

  sortData(sort) {
    this.sortOrder = sort.direction !== '' ? sort.direction : null;
    this.sortBy    = sort.direction !== '' ? sort.active : null;

    this.fetchInstallments(this.componentType);
  }

  public openChangeAmountModal(installment: PaymentTerm) {
    const dialogRef = this.dialog.open(ChangeAmountModalComponent, {
      width: '50%',
      data:  {
        term: installment
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchInstallments(this.componentType);
      }
    });
  }

  public openAdditionalAmountEditorModal(installment: PaymentTerm) {
    const dialogRef = this.dialog.open(CaseInstallmentAmountEditorComponent, {
      width: '50%',
      data:  {
        installment
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.fetchInstallments(this.componentType);
    });
  }

  public requestPaymentDialog(paymentMethodType: PaymentMethodType, paymentProcessorType: PaymentProcessorType): void {
    const nextTerm       = this.upcomingInstallments.find(t => t.amount > t.amount_paid);
    let maxPayableAmount = 0;
    this.upcomingInstallments.forEach(term => {
      maxPayableAmount += term.amount - term.amount_paid;
    });

    maxPayableAmount = +maxPayableAmount.toFixed(2);

    if (nextTerm) {
      const dialogRef = this.dialog.open(RequestPaymentModalComponent, {
        width:        '50%',
        minHeight:    200,
        disableClose: true,
        autoFocus:    false,
        data:         {
          caseId:                this.case.id,
          paymentMethodType,
          paymentProcessorType,
          amount:                (nextTerm.amount - nextTerm.amount_paid).toFixed(2),
          maxPayableAmount,
          forceDefaultProcessor: this.forceDefaultProcessor
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.success) {
            this.toastr.success(this.translate.instant(
              'CASES.single.request-payment-success'), this.translate.instant('SHARED.success')
            );
          } else {
            this.toastr.error(this.translate.instant(
              'CASES.single.request-payment-error'), this.translate.instant('SHARED.error')
            );
          }
        }
      });
    } else {
      this.toastr.error('No installment available');
    }
  }

  public openDialog(relocate = false): void {
    this.buildForm();
    const dialogRef = this.dialog.open(RecordPaymentModalComponent, {
      width: '80%',
      data:  {
        form: this.recordForm,
        relocate
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchInstallments(this.componentType);
        if (relocate) {
          this.toastr.success(this.translate.instant('CASES.single.payment-relocated-success'),
            this.translate.instant('SHARED.success'));
        } else {
          this.toastr.success(this.translate.instant('CASES.single.payment-record-success'),
            this.translate.instant('SHARED.success'));
        }
        this.fetchInstalments.emit();
      }
    });
  }

  public openCardModal() {
    const nextTerm       = this.upcomingInstallments.find(t => t.amount > t.amount_paid);
    const chargeAmount   = nextTerm ? (nextTerm.amount - nextTerm.amount_paid).toFixed(2) : 0;
    let maxPayableAmount = 0;
    this.upcomingInstallments.forEach(term => {
      maxPayableAmount += term.amount - term.amount_paid;
    });

    if (nextTerm) {
      const dialogRef = this.dialog.open(CardInfoModalComponent, {
        width:        '60%',
        minHeight:    200,
        disableClose: true,
        panelClass:   'custom-mat-dialog',
        autoFocus:    false,
        data:         {
          caseId:                this.case.id,
          amountInput:           true,
          type:                  'Manual charge',
          payment:               'oppwa',
          amount:                chargeAmount,
          maxPayableAmount,
          forceDefaultProcessor: this.forceDefaultProcessor
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.reFetch) {
          this.fetchInstallments(this.componentType);
          this.fetchInstalments.emit();
          this.displayDistributionWarningModal();
        }
      });
    }
  }

  public buildForm() {
    const defaultPaymentMethod = this.paymentMethods.find(m => m.slug === 'sepa');
    const defaultIncomeAccount = defaultPaymentMethod.billing_bank_accounts.find(account => account.default === true);
    const defaultPlanId        = this.paymentPlanTypes.find(planType => planType.slug === 'debt_plan');

    this.recordForm = this.fb.group({
      case_id:           this.case.id,
      payment_plan_id:   defaultPlanId?.id ? defaultPlanId.id : null,
      note:              '',
      payment_date:      [new Date()],
      payment_method_id: [defaultPaymentMethod.id],
      payment_method:    [defaultPaymentMethod.slug],
      income_account_id: [defaultIncomeAccount.id, Validators.required],
      split_type:        ['custom', null],
      amount:            [0, Validators.required],
      amount_paid:       [null, Validators.required],
      phase_one_amount:  [0, null],
      phase_two_amount:  [0, null],
      phase_one_paid:    [
        this.upcomingInstallments.filter(
          term => (term.type === null || term.type === 'phase_one' || term.type === 'deferred_one') && term.amount >
            term.amount_paid).length === 0, null],
      phase_two_paid:    [
        this.upcomingInstallments.filter(
          term => (term.type === 'phase_two' || term.type === 'deferred_two') && term.amount >
            term.amount_paid).length === 0, null],
      terms:             this.fb.array([]),
      terms_phase_one:   this.fb.array(
        this.upcomingInstallments.filter(
          term => term.type === null || term.type === 'phase_one' || term.type === 'deferred_one')
      ),
      terms_phase_two:   this.fb.array(this.upcomingInstallments.filter(term => term.type === 'phase_two'))
    });
    this.addInstalments();
    this.calculateAmountPaid();
  }

  addInstalments() {
    const instalments = this.recordForm.get('terms') as UntypedFormArray;
    this.upcomingInstallments.forEach(term => {
      instalments.push(
        this.fb.group({
          id:              term.id,
          term_date:       term.term_date,
          date_paid:       term.date_paid,
          type:            term.type,
          name:            term.name,
          amount:          term.amount,
          amount_paid:     0,
          old_amount_paid: term.amount_paid
        })
      );
    });
  }

  calculateAmountPaid() {
    let phaseOneAmount = 0;
    let phaseTwoAmount = 0;
    let amountPaid     = 0;
    let amountPayable  = 0;
    this.upcomingInstallments.forEach(term => {
      amountPayable += term.amount - term.amount_paid;
      amountPaid += term.amount_paid;
      if (term.type === null || term.type === 'phase_one' || term.type === 'deferred_one') {
        phaseOneAmount += term.amount - term.amount_paid;
      } else if (term.type === 'phase_two' || term.type === 'deferred_two') {
        phaseTwoAmount += term.amount - term.amount_paid;
      }
    });
    amountPayable = +amountPayable.toFixed(2);
    this.recordForm.get('phase_one_amount').setValue(phaseOneAmount);
    this.recordForm.get('phase_two_amount').setValue(phaseTwoAmount);
    this.recordForm.get('amount_paid').setValue(amountPaid);
    this.recordForm.get('amount').setValidators([Validators.required, Validators.max(Math.min(amountPayable, 4000))]);
  }

  public handleResponse(response: LaravelResourceResponse) {
    //  Get total amount of paid terms and total amount to pay
    this.filteredInstallmentsBalance.totalToPay = 0;
    this.filteredInstallmentsBalance.totalPaid  = 0;
    response.data.forEach(term => {
      this.filteredInstallmentsBalance.totalToPay += term.amount;
      this.filteredInstallmentsBalance.totalPaid += term.amount_paid;
    });

    const onlyUnpaid = response.data.filter(obj => obj.amount !== obj.amount_paid);

    this.totalResults               = response.data.total;
    this.upcomingInstallments       = response.data;
    this.unpaidInstallments         = onlyUnpaid;
    this.dataSource                 = new MatTableDataSource<PaymentTerm>(response.data);
    this.dataSourceUnpaid           = new MatTableDataSource<PaymentTerm>(onlyUnpaid);
    this.dataSource.paginator       = this.paginator;
    this.dataSource.sort            = this.sort;
    this.dataSourceUnpaid.paginator = this.paginator;
    this.dataSourceUnpaid.sort      = this.sort;
    this.paginatorConfig.length     = response.data.length;
  }

  public getPercent(paid, amount) {
    if ((paid / amount) * 100) {
      return ((paid / amount) * 100).toFixed(2);
    } else {
      return 0;
    }
  }

  deleteAndRebuild() {
    this.isLoading++;
    this.dataSource = new MatTableDataSource<PaymentTerm>([]);

    this.paymentService.deleteAllPayments(this.case.id) //  Delete all installments
      .subscribe(
        value => {
          this.paymentService.rebuildInstallmentPlan(this.case.id)  //  Rebuild installments
            .pipe(finalize(() => this.isLoading--))
            .subscribe(
              res => {
                this.fetchInstallments(this.componentType);
                this.toastr.success(this.translate.instant('CASES.single.rebuild-plan-note-success'));
              },
              error => {
                this.toastr.error(error.message, this.translate.instant('SHARED.error'));
              }
            );
        },
        error => {
          this.toastr.error(error.message, this.translate.instant('SHARED.error'));
        }
      );
  }

  rebuild() {
    this.isLoading++;
    this.dataSource = new MatTableDataSource<PaymentTerm>([]);

    this.paymentService.rebuildInstallmentPlan(this.case.id)  //  Rebuild installments
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.fetchInstallments(this.componentType);
          this.toastr.success(this.translate.instant('CASES.single.rebuild-plan-note-success'));
        },
        error => {
          this.toastr.error(error.message, this.translate.instant('SHARED.error'));
        }
      );
  }

  rebuildManual(data) {
    this.isLoading++;
    this.dataSource = new MatTableDataSource<PaymentTerm>([]);

    this.paymentService.rebuildInstallmentPlanManual(data)  //  Rebuild installments
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.fetchInstallments(this.componentType);
          this.toastr.success(this.translate.instant('CASES.single.rebuild-plan-note-success'));
        },
        error => {
          this.toastr.error(error.message, this.translate.instant('SHARED.error'));
        }
      );
  }

  public openPaymentPlanGenerationModal() {
    const dialogRef = this.dialog.open(CasePaymentPlanGeneratorComponent, {
      width: '60%',
      data:  {
        case:            this.case,
        debtPaymentPlan: this.case.debt_payment_plan,
        paymentPlanFilter: {only_debt_claim: 1}
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.fetchInstallments(this.componentType);
    });
  }

  public openCustomPaymentPlanGenerationModal(): void {
    const dialogRef = this.dialog.open(CasePaymentPlanGeneratorComponent, {
      width: '60%',
      data: {
        case: this.case,
        debtPaymentPlan: this.case.debt_payment_plan,
        paymentPlanFilter: {only_attorney_initial: 1}
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.fetchInstallments(this.componentType);
    });
  }

  deleteAllPayments(): void {
    Swal.fire({
      title:              this.translate.instant('CASES.single.remittance-request-text'),
      text:               this.translate.instant('CASES.single.remittance-request-warning'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translate.instant('SHARED.no'),
      confirmButtonText:  this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#886ab5'
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.dataSource = new MatTableDataSource<PaymentTerm>([]);

        this.paymentService.deleteAllPayments(this.case.id).pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.fetchInstallments(this.componentType);
              this.toastr.success(this.translate.instant('CASES.single.remittance-request-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('CASES.single.remittance-request-error'));
            }
          );
      }
    });
  }

  private fetchPaymentMethods(componentType: 'admin' | 'distribution'): void {
    if (componentType === 'distribution') {
      this.paymentMethods = [];
      return;
    }
    this.isLoading++;
    this.paymentMethodService.index({}, ['billing_bank_accounts']).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.paymentMethods = result.data;
        this.buildForm();
      });
  }

  private fetchPaymentPlanTypes(componentType: 'distribution' | 'admin') {
    this.isLoading++;
    this.paymentPlanTypeService.index().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.paymentPlanTypes = componentType === 'admin' ?
                                result.data :
                                result.data.filter(planType => planType.default === true);
        this.fetchPaymentMethods(this.componentType);
      });
  }

  changePaymentMethod($event): void {
    this.isLoading++;
    const data = { payment_method: $event.target.value };
    this.paymentMethodService.updateCasePaymentMethod(this.case.id, data).pipe()
      .subscribe(res => {
          this.isLoading--;
          this.toastr.success('', this.translate.instant('SHARED.success')
          );
        },
        error => {
          this.isLoading--;
          this.toastr.error('', this.translate.instant('SHARED.error'));
        }
      );
  }

  paymentMethodIsSelected($paymentMethodId: number): boolean {
    return $paymentMethodId === this.case?.debt_payment_plan?.payment_method_id;
  }

  displayDistributionWarningModal(): void {
    Swal.fire({
      text:               this.translate.instant('DISTRIBUTION.modals.confirm-payment'),
      icon:               'warning',
      showCancelButton:   false,
      confirmButtonText:  'OK',
      confirmButtonColor: '#4267b2'
    });
  }

  public updateComponentType(type: 'distribution' | 'admin') {
    this.componentType    = type;
    this.displayedColumns = this.getTableColumns(type);
    this.fetchInstallments(type);
  }

  private getTableColumns(type: 'distribution' | 'admin'): Array<string> {
    const columns = [
      'select',
      'payment-plan-type',
      'case-creditor',
      'creditor-name',
      'claim-type',
      'next-payment',
      'date-paid',
      'amount'
    ];
    if (type === 'admin') {
      columns.push('actions');
    }

    return columns;
  }

  public deleteInstallment(termId: number) {
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('SHARED.action.delete_confirmation',
        { model: this.translate.instant('INSTALLMENTS.model_name.singular') }),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonColor: '#886ab5'
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.dataSource = new MatTableDataSource<PaymentTerm>([]);

        this.paymentService.deleteCaseInstallment(this.case.id, termId).pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.fetchInstallments(this.componentType);
              this.toastr.success(this.translate.instant('SHARED.submit_result.delete.success', {
                model: this.translate.instant('INSTALLMENTS.model_name.singular')
              }));
            },
            error => {
              this.toastr.error(this.translate.instant('SHARED.submit_result.delete.error', {
                model: this.translate.instant('INSTALLMENTS.model_name.singular')
              }));
            }
          );
      }
    });
  }

  public markAsPromo(installment: PaymentTerm) {
    this.isLoading++;
    this.paymentService.caseInstallmentMarkAsPromo(this.case.id, installment.id).pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          this.toastr.success(this.translate.instant('SHARED.submit_result.update.success', {
            model: this.translate.instant('INSTALLMENTS.model_name.singular')
          }));
        },
        error => {
          this.toastr.error(this.translate.instant('SHARED.submit_result.update.error', {
            model: this.translate.instant('INSTALLMENTS.model_name.singular')
          }));
        }
      );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(selection, data) {
    const numSelected = selection.selected.length;
    const numRows     = data.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(selection, data) {
    if (this.isAllSelected(selection, data)) {
      selection.clear();
      return;
    }

    selection.select(...data.data);
  }

  deletePayment($event, selectionPayment) {
    $event.preventDefault();
    const installment_ids = [];
    this.isLoading++;
    selectionPayment._selected.forEach(selected => installment_ids.push(selected.id));
    this.paymentService.deletePayments(this.case.id, installment_ids).pipe(finalize(() => this.isLoading--))
      .subscribe(
        value => {
          this.fetchInstallments(this.componentType);
          this.toastr.success(this.translate.instant('SHARED.item-deleted'));
        },
        error => {
          this.toastr.error(this.translate.instant('SHARED.went-wrong'));
        }
      );
  }

  public changePaymentStatus(installment: PaymentTerm): void {
    const dialogRef = this.dialog.open(PaymentStatusModalComponent, {
      width: '50%',
      data:  {
        term: installment
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchInstallments(this.componentType);
      }
    });
  }

  public splitTerm(installment: PaymentTerm): void {
    const dialogRef = this.dialog.open(SplitTermComponent, {
      width: '50%',
      data: {
        term: installment
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.fetchInstallments(this.componentType);
      }
    });
  }

}
