import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { PaymentTransaction } from '../../../../../../../../_base-shared/models/Payment/Transaction/PaymentTransaction';
import { DistributionService } from '../../../../distribution/distribution.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-case-transaction-list',
  templateUrl: './case-transaction-list.component.html',
  styles:      [],
})
export class CaseTransactionListComponent implements OnInit {
  @Input() case: Case;
  @Input() reFetchTransactions: EventEmitter<any>;
  @Input() componentType: 'distribution' | 'admin';
  public transactions: Array<PaymentTransaction> = [];

  constructor(private caseService: CaseService,
              private distributionService: DistributionService) {
  }

  ngOnInit(): void {
    this.reFetchTransactions.subscribe(next => this.fetchTransactions(this.componentType));
    this.fetchTransactions(this.componentType);
  }

  public updateComponentType(type: 'distribution' | 'admin') {
    this.componentType = type;
    this.fetchTransactions(type);
  }

  private fetchTransactions(componentType: 'admin' | 'distribution') {
    const data = {case_id: this.case.id, all_results: 1, success_status: null};

    if (componentType === 'distribution') {
      data.success_status = 1;
    }

    const observable = componentType === 'admin' ?
        this.caseService.getTransactions(data, ['payment_processor']) :
        this.distributionService.indexTransactions(this.case.uuid, data, ['payment_processor']);

    observable.subscribe(result => {
      this.transactions = result.data;
      //  Format response string to show in prettier
      this.transactions.map(transaction => {
        return transaction.response = JSON.stringify(JSON.parse(transaction.response), null, 4);
      });
    });
  }
}
