import { Affiliate } from '../Affiliate';
import { CaseContract } from '../CaseDocument/CaseContract';
import { Proposal } from '../CaseDocument/Proposal';
import { CaseDepartmentAssignment } from '../Department/CaseDepartmentAssignment';
import { CaseDistribution } from '../Distribution/CaseDistribution';
import { AppDocument } from '../Document/AppDocument';
import { AppointedCourt } from '../Entity/AppointedCourt';
import { Note } from '../Note';
import { EmailHistory } from '../Notification/EmailHistory';
import { SmsHistory } from '../Notification/SmsHistory';
import { Packager } from '../Packager/Packager';
import { CasePaymentPlan } from '../Payment/CasePaymentPlan';
import { PaymentHistory } from '../Payment/PaymentHistory';
import { PaymentRegistration } from '../Payment/PaymentRegistration';
import { PaymentTerm } from '../Payment/PaymentTerm';
import { PaymentTransaction } from '../Payment/Transaction/PaymentTransaction';
import { Product } from '../Product';
import { CallStatus } from '../Status/CallStatus';
import { PackagerStatus } from '../Status/PackagerStatus';
import { PaymentStatus } from '../Status/PaymentStatus';
import { Status } from '../Status/Status';
import { Task } from '../Task/Task';
import { User } from '../User/User';
import { CaseAsset } from './CaseAsset';
import { CaseAssetBankAccount } from './CaseAssetBankAccount';
import { CaseAssetOther } from './CaseAssetOther';
import { CaseAssetProperty } from './CaseAssetProperty';
import { CaseAssetVehicle } from './CaseAssetVehicle';
import { CaseCreditor, CaseCreditorPivot } from './CaseCreditor';
import { CaseCreditorEntity } from './CaseCreditorEntity';
import { CaseExpense } from './CaseExpense';
import { CaseIncome } from './CaseIncome';
import { CasePublicDebt } from './CasePublicDebt';
import { Lead } from './Lead';
import { UserCaseRelationValidation } from './UserCaseRelationValidation';

export class CasePrefillData {
  affiliate_id?: number;
  legal_advisor_id?: number;
  verifier_id?: number;
}

export class CaseVariable {
  label: string;
  description?: string;
}

export class Case {
  id?: number;
  uuid?: string;
  product_id?: number;
  status_id: number;
  payment_status_id: number;
  call_status_id?: number;
  packager_status_id?: number;
  user_id: number;
  partner_user_id: number;
  packager: Packager;
  packager_id: number;
  original_packager_id: number;

  ref_number: string;
  joint_application: boolean;
  active: boolean | 0 | 1;
  payment_active: boolean | 0 | 1;
  require_lso_qualifiers: boolean | 0 | 1;
  product_assigned_at: Date | string;
  distribution_nonviable?: boolean | 0 | 1;
  is_test?: boolean | 0 | 1;
  last_action_at?: string | Date;
  created_at?: Date | string;
  updated_at?: Date | string;
  deleted_at?: Date | string;

  // Relations
  client?: User;
  partner?: User;
  aep?: any;
  creditors: Array<CaseCreditorPivot>;
  secured_creditors: Array<CaseCreditorPivot>   = [];
  unsecured_creditors: Array<CaseCreditorPivot> = [];
  public_debts: Array<CasePublicDebt>           = [];
  claim_creditors: Array<CaseCreditorPivot>     = [];
  proposal?: Proposal;
  income                                        = new CaseIncome();
  expense                                       = new CaseExpense();
  debt_payment_plan: CasePaymentPlan;
  payment_plans: CasePaymentPlan;
  config: any;
  assets: Array<CaseAsset>;
  file_requests?: Array<any>;
  partner_file_requests?: Array<any>;
  files?: Array<any>;
  legal_documents?: Array<any>;
  pending_files?: Array<any>;
  client_files?: Array<any>;
  partner_files?: Array<any>;
  contracts?: Array<any>;
  latest_contract?: CaseContract;
  status?: Status;
  payment_status?: PaymentStatus;
  call_status?: CallStatus;
  product?: Product;
  payment_history?: Array<PaymentHistory>;
  transactions?: Array<PaymentTransaction>;
  terms?: Array<PaymentTerm>;
  unpaid_terms?: Array<PaymentTerm>;
  paid_terms?: Array<PaymentTerm>;
  missed_terms?: Array<PaymentTerm>;
  payment_registrations?: Array<PaymentRegistration>;
  last_payment_registration?: PaymentRegistration;
  notes?: Array<Note>;
  lead?: Lead;
  case_contract_logs?: Array<any>;
  email_history?: Array<EmailHistory>;
  sms_history?: Array<SmsHistory>;
  payment_notes?: Array<Note>;
  case_status_logs?: Array<any>;
  latest_case_status_log?: any;
  case_payment_status_logs?: Array<any>;
  case_general_logs?: Array<any>;
  affiliate?: Array<Affiliate>;
  propuesta_modelo?: any;
  propuesta_modelo_logs?: Array<any>;
  contract_file_logs?: Array<any>;
  tasks?: Array<Task>;
  case_creditor_entities?: CaseCreditorEntity;
  case_creditors?: Array<CaseCreditor>;
  linked_by_cases?: Array<Case>;
  linked_cases?: Array<Case>;
  relation_validations?: Array<UserCaseRelationValidation>;
  distribution?: CaseDistribution;
  transfer_request?: any;
  packager_status: PackagerStatus;
  original_packager: Packager;

  payment = new CasePaymentPlan();

  department_assignments?: Array<CaseDepartmentAssignment>;
  appointed_court?: AppointedCourt;
  creditor_list_documents?: Array<AppDocument>;
  asset_list_documents?: Array<AppDocument>;
  debt_cancellation_request_documents?: Array<AppDocument>;
  proposal_documents?: Array<AppDocument>;

  // App Helpers
  signature_verified?: boolean;
  linked_cases_ids: Array<number>;
  // Deprecated, TODO: rework
  files_by_type?: any;
  client_files_by_type?: any;
  partner_files_by_type?: any;
  files_by_creditor?: any;
  files_by_public_debt?: any;
  partner_files_by_creditor?: any;
  partner_files_by_public_debt?: any;
  legal_documents_client?: any;
  legal_documents_partner?: any;
  client_files_by_bank?: any;
  partner_files_by_bank?: any;
  court_documents_client?: any;
  court_documents_partner?: any;
  assets_by_type: {
    bank_accounts: CaseAssetBankAccount[];
    vehicles: CaseAssetVehicle[];
    properties: CaseAssetProperty[];
    other: CaseAssetOther[];
  };
  earnings?: number;
}
