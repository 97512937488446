import { NgModule } from '@angular/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { SharedModule } from '../../_shared/shared.module';
import { PaymentListFiltersComponent } from './payment-list/payment-list-filters/payment-list-filters.component';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { PaymentRoutingModule } from './payment-routing.module';

@NgModule({
  declarations: [
    PaymentListComponent,
    PaymentListFiltersComponent,
  ],
  imports:      [
    SharedModule,
    MatTabsModule,
    MatMenuModule,
    PaymentRoutingModule,
  ],
})
export class PaymentModule {
}
