import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { CardInfoModalComponent } from '../../../_shared/components/card-info-modal/card-info-modal.component';
import {
  ChangeAmountModalComponent,
} from '../../../_shared/components/change-amount-modal/change-amount-modal.component';
import { ChangeDateModalComponent } from '../../../_shared/components/change-date-modal/change-date-modal.component';
import { PaymentListFilter } from '../../../../../../_base-shared/models/Payment/PaymentListFilter';
import { PaymentAction, PaymentMethod, PaymentMethodType } from '../../../../../../_base-shared/models/Payment/PaymentMethod';
import { PaymentTerm } from '../../../../../../_base-shared/models/Payment/PaymentTerm';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseBulkActionModalComponent } from '../../case/case-bulk-action-modal/case-bulk-action-modal.component';
import { DialLeadListSelectorComponent } from '../../lead/dial-lead-list-selector/dial-lead-list-selector.component';
import { NotificationMessageComponent } from '../../notification/notification-message/notification-message.component';
import { PaymentMethodService } from '../payment-method.service';
import { PaymentService } from '../payment.service';
import { PaymentListFiltersComponent } from './payment-list-filters/payment-list-filters.component';
import { DateTime } from 'luxon';

@Component({
  selector:    'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls:   ['./payment-list.component.scss'],
})
export class PaymentListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('changeDateModal') dateModal: ElementRef; // TODO:
  // Wrappper
  @ViewChild(PaymentListFiltersComponent) paymentListFiltersComponent: PaymentListFiltersComponent;
  public bulkNotificationForm: UntypedFormGroup;
  public bulkPaymentActionForm: UntypedFormGroup;
  public onlyRelated: boolean;
  // End of wrapper

  public isLoadingExport = 0;
  public authUser: User;

  public filtersReady     = false;
  public showDistribution = false;
  public paymentListFilter: PaymentListFilter;

  // Datatable
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public serverResponse: LaravelResourceResponse;
  public isLoadingPayments                           = 0;
  public initialSelection                            = [];
  public allowMultiSelect                            = true;
  public selection                                   = new SelectionModel(this.allowMultiSelect, this.initialSelection);
  public pageSelected                                = false;
  public displayedColumns: Array<string>             = [
    'select',
    'ref_number',
    'label',
    'first_name',
    'last_name',
    'payment_method',
    'payment_status',
    'term_date',
    'date_paid',
    'amount',
    'has_card',
    'status',
    'actions',
  ];
  public totalResults: number;
  public totalPages: number;
  public dataSource: MatTableDataSource<PaymentTerm> = new MatTableDataSource<PaymentTerm>([]);
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'date_paid' | 'term_date' };
  // End Datatable
  private initialFetchComplete: boolean;
  private subscriptions: Array<Subscription>         = [];

  // New
  // public paymentMethodSelected: PaymentMethodType; // TODO?
  public bulkPaymentOptions: Array<{ label: string, value: string }>;

  public changeDateForm: UntypedFormGroup;
  public changeDateTermId;
  public disableApplyBtn = false;

  // Legacy
  public validationError            = '';
  public successMessage             = '';
  public processingPayment          = 0;
  public totalAmount                = 0;
  public totalActionableAmount      = 0;
  public billableAmount             = 0;
  public distributionBillableAmount = 0;
  public distributionAmountBilled   = 0;
  public amountBilled               = 0;
  public activeClients              = 0;
  public paymentMethods: PaymentMethod [];

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private toast: ToastrService,
              public dialog: MatDialog,
              public translate: TranslateService,
              public globalEventsService: MainGlobalEventService,
              private paymentService: PaymentService,
              private paymentMethodService: PaymentMethodService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => this.authUser = user));
    this.onlyRelated = this.route.snapshot.data.hasOwnProperty('onlyRelated') ?
      this.route.snapshot.data.onlyRelated :
      null;

    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'asc', active: 'term_date'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.fetchPaymentMethods();
    this.buildForms();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private buildForms() {
    this.bulkPaymentOptions    = [{label: 'Select method', value: null}];
    this.bulkPaymentActionForm = this.fb.group({
      payment_action: [null, Validators.required],
    });

    this.bulkNotificationForm = this.fb.group({
      action: [{value: '', disabled: false}, Validators.required],
    });

    this.changeDateForm = this.fb.group({
      newDate:      [],
      newDateRadio: ['this_payment'],
    });
  }

  public clearFilters(): void {
    this.paymentListFiltersComponent.clearFilters();
  }

  public sendNotifications(bulkNotificationForm: UntypedFormGroup) {
    if (bulkNotificationForm.invalid || !bulkNotificationForm.value.action || this.selection.selected?.length === 0) {
      return;
    }

    this.openNotificationModal(bulkNotificationForm.value.action, this.paymentListFilter);
  }

  public exportRecords(paymentListFilter: PaymentListFilter) {
    paymentListFilter.terms = [];
    this.selection.selected.forEach(selectedTerm => paymentListFilter.terms.push(selectedTerm.id));

    if (!paymentListFilter.terms.length) {
      paymentListFilter.select_all = 1;
    }

    paymentListFilter.expected_count = paymentListFilter.select_all ?
      this.totalResults :
      paymentListFilter.terms.length;
    const requestFilter              = MainBaseApiService.convertFiltersForRequest(paymentListFilter, 'get');

    this.isLoadingExport++;
    this.subscriptions.push(
      this.paymentService.exportTerms(requestFilter).pipe(finalize(() => this.isLoadingExport--)).subscribe(
        result => {
          this.toast.success(result.message);
          // const fileName = this.translate.instant('PAYMENTS.list.export.file-name') +
          //     '_' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
          // saveAs(result.body, fileName);
          this.clearSelection();
        },
        err => this.serverResponse = err.error,
      ),
    );
    this.paymentListFilter.terms = null;
  }

  public dialerListIdSelector() {
    const dialogRef = this.dialog.open(DialLeadListSelectorComponent, {
      width:     '50%',
      minHeight: '230px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.params?.list_id) {
        this.pushToDialer(this.paymentListFilter, result.params.list_id, result.params.owned_by);
      }
      this.clearSelection();
    });
  }

  public pushToDialer(paymentListFilter: PaymentListFilter, dialListId: string, ownedBy: any) {
    const oldSelectAll      = paymentListFilter.select_all;
    paymentListFilter.terms = [];
    this.selection.selected.forEach(selectedTerm => paymentListFilter.terms.push(selectedTerm.id));

    if (!paymentListFilter.terms.length && !paymentListFilter.select_all) {
      paymentListFilter.select_all = 1;
    }

    paymentListFilter.expected_count = paymentListFilter.select_all ?
      this.totalResults :
      paymentListFilter.terms.length;
    const requestFilter              = MainBaseApiService.convertFiltersForRequest(paymentListFilter, 'post');
    requestFilter.dialer_list_id     = dialListId.toString();
    requestFilter.owned_by           = ownedBy;

    this.isLoadingExport++;
    this.subscriptions.push(
      this.paymentService.pushToDialer(requestFilter).pipe(finalize(() => this.isLoadingExport--)).subscribe(
        result => {
          this.toast.success(this.translate.instant('CASES.list.push_to_dialer.success'));
        },
        err => {
          this.toast.error(this.translate.instant('CASES.list.push_to_dialer.error'));
        },
      ),
    );
    paymentListFilter.select_all = oldSelectAll;
    this.paymentListFilter.terms = null;
  }

  public handleFiltersState($event: boolean): void {
    this.filtersReady = $event;
  }

  public handleDistributionVisibilityState($event: boolean): void {
    this.showDistribution = $event;
    this.fetchPayments();
  }

  public handleFilters($event: PaymentListFilter): void {
    const oldPaymentMethod = this.paymentListFilter?.payment_method;
    this.paymentListFilter = $event;
    if (oldPaymentMethod !== this.paymentListFilter.payment_method) {
      this.paymentMethodChanged(this.paymentListFilter.payment_method);
    }
    if (!this.initialFetchComplete) {
      this.applyFilters();
      this.initialFetchComplete = true;
    }
  }

  private resetPagination(): void {
    this.paginatorConfig            = this.defaultPaginatorConfig;
    this.paymentListFilter.per_page = this.paginatorConfig.pageSize;
    this.paymentListFilter.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.paymentListFilter.sort_by = this.defaultSort.active;
    this.paymentListFilter.order   = this.defaultSort.direction;
  }

  private fetchPayments(): void {
    this.clearSelection();  //  Reset global select
    // this.form.patchValue({select_all: 0}, {emitEvent: false, onlySelf: true}); // Note: Overrides SELECT ALL untill 100% ready
    this.paymentListFilter.start_date = this.paymentListFilter.start_date &&
    this.paymentListFilter.start_date instanceof Date ?
      this.paymentListFilter.start_date.toISOString() :
      this.paymentListFilter.start_date;
    this.paymentListFilter.end_date   = this.paymentListFilter.end_date &&
    this.paymentListFilter.end_date instanceof Date ?
      this.paymentListFilter.end_date.toISOString() :
      this.paymentListFilter.end_date;
    const withBatchStatusesRequestData    = MainBaseApiService.convertFiltersForRequest(
      {...this.paymentListFilter, has_batch_in_statuses: ['funded']}, 'get',
    );
    const withoutBatchStatusesRequestData = MainBaseApiService.convertFiltersForRequest(
      {
        ...this.paymentListFilter,
        doesnt_have_batch_in_statuses: this.showDistribution ? ['funded'] : null,
      }, 'get',
    );

    const withRelations = [
      'case.client',
      'case.debt_payment_plan',
      'case.payment_registrations',
      'case.payment_status',
      'case_payment_plan.type',
    ];

    this.dataSource = new MatTableDataSource<PaymentTerm>([]);
    this.isLoadingPayments++;
    this.subscriptions.push(
      this.paymentService.index(this.paymentListFilter, withRelations).pipe(finalize(() => this.isLoadingPayments--))
        .subscribe(
          result => {
            this.clearSelection();
            this.dataSource             = new MatTableDataSource<PaymentTerm>(result.data);
            console.log(result.data);
            this.dataSource.sort        = this.sort;
            this.paginatorConfig.length = result.meta.total;
            this.totalResults           = result.meta.total;
            this.totalPages             = result.meta.last_page;
          },
          error => this.serverResponse = error.error,
        ),
      this.paymentService.getBillableAmount(withoutBatchStatusesRequestData).subscribe(
        result => this.billableAmount = result.data.billable_amount,
        error => console.log(error),
      ),
      this.paymentService.getBillableAmount(withBatchStatusesRequestData).subscribe(
        result => this.distributionBillableAmount = result.data.billable_amount,
        error => console.log(error),
      ),
      this.paymentService.getAmountBilled(withoutBatchStatusesRequestData).subscribe(
        result => this.amountBilled = result.data,
        error => console.log(error),
      ),
      this.paymentService.getAmountBilled(withBatchStatusesRequestData).subscribe(
        result => this.distributionAmountBilled = result.data,
        error => console.log(error),
      ),
      this.paymentService.getActiveClients(withoutBatchStatusesRequestData).subscribe(
        result => this.activeClients = result.data,
        error => console.log(error),
      ),
    );
  }

  // TODO: save paginate settings to localStorage
  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.paymentListFilter.page     = this.paginatorConfig.pageIndex + 1;
    this.paymentListFilter.per_page = this.paginatorConfig.pageSize;
    this.fetchPayments();
  }

  public sortData(sort) {
    if (sort.direction === '') {
      sort.direction = this.paymentListFilter.order === 'asc' ? 'desc' : 'asc';
    }
    this.paymentListFilter.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.paymentListFilter.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchPayments();
  }

  private clearSelection() {
    this.selection.clear();
    this.pageSelected = false;
    this.patchSelectAllFilter(0);
    this.totalAmount = 0;
    delete this.paymentListFilter.terms;
  }

  private patchSelectAllFilter(state: boolean | 0 | 1) {
    if (this.paymentListFiltersComponent) {
      this.paymentListFiltersComponent.patchFilter('select_all', state, {emitEvent: false, onlySelf: true});
      this.paymentListFilter.select_all = state;
    } else {
      console.log('Filter component not ready for form control patching');
    }
  }

  private updateTotalAmount(actionAmount: number, toggle: boolean) {
    if (toggle) {
      this.totalAmount += actionAmount;
    } else {
      this.totalAmount -= actionAmount;
    }
  }

  private openNotificationModal(type: string, paymentListFilter: PaymentListFilter) {
    paymentListFilter.terms = [];
    this.selection.selected.forEach(selectedTerm => paymentListFilter.terms.push(selectedTerm.id));
    paymentListFilter.expected_count = paymentListFilter.select_all ?
      this.totalResults :
      paymentListFilter.terms.length;
    const requestFilters             = MainBaseApiService.convertFiltersForRequest(paymentListFilter, 'post');

    const channels = [];
    if (type === 'all' || type === 'email') {
      channels.push('email');
    }
    if (type === 'all' || type === 'sms') {
      channels.push('sms');
    }

    const data      = {
      templatableType: 'term',
      channels,
      filters:         requestFilters,
    };
    const dialogRef = this.dialog.open(NotificationMessageComponent, {
      width:       '50%',
      minHeight:   '230px',
      hasBackdrop: false,
      data,
    });
    dialogRef.afterClosed().subscribe(result => this.clearSelection());
  }

  public togglePageSelect($event) {
    this.pageSelected = $event.checked;
    if (!this.pageSelected) {
      this.patchSelectAllFilter(0);
    }
    if (this.allRowsSelected()) {
      this.dataSource.data.forEach(term => this.selection.deselect(term));
    } else {
      const paymentAction = this.bulkPaymentActionForm.get('payment_action').value;
      this.dataSource.data.forEach(term => {
        this.selection.select(term);
        if (this.paymentListFilter.payment_method && this.isCheckboxVisible(term)) {
          this.calculateAmountSelected(term, paymentAction);
        }
      });
    }
  }

  public allRowsSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  // ------------------------------ ADDED NEW -------------------------//
  public paymentMethodChanged(paymentMethod: PaymentMethodType) {
    this.clearSelection();

    if (paymentMethod === 'card' || paymentMethod === 'wire') {
      this.bulkPaymentOptions = [{label: this.translate.instant('PAYMENTS.list.bulk-actions.charge'), value: 'charge'}];
      this.patchPaymentAction('charge');
    }

    if (paymentMethod === 'sepa') {
      this.bulkPaymentOptions = [
        {label: this.translate.instant('PAYMENTS.list.bulk-actions.pending'), value: 'pending'},
        {label: this.translate.instant('PAYMENTS.list.bulk-actions.paid'), value: 'paid'},
        {label: this.translate.instant('PAYMENTS.list.bulk-actions.failed'), value: 'failed'},
      ];
      this.patchPaymentAction('pending');
    }
  }

  public patchPaymentAction(paymentAction: string) {
    if (this.bulkPaymentActionForm.get('payment_action').value !== paymentAction) {
      this.paymentActionChanged(paymentAction);
    }
  }

  public paymentActionChanged(paymentAction: string) {
    // TODO: payment_action not sent in filters, might not need refresh
    this.clearSelection();
    if (paymentAction === 'charge' || paymentAction === 'pending') {
      // TODO: if refresh is off && select_all is on, call API and check total actionable amount/records
    }
  }

  public getPercent(paid, amount): string {
    if ((paid / amount) * 100) {
      return ((paid / amount) * 100).toFixed(2);
    } else {
      return '0';
    }
  }

  // ------------------------------ LEGACY -------------------------//
  public toggledRow(event: MatCheckboxChange, paymentTerm: PaymentTerm) {
    this.selection.toggle(paymentTerm);
    if (!event.checked) {
      this.pageSelected = false;  //  Hide global select
      this.patchSelectAllFilter(0);
    }

    const paymentAction = this.bulkPaymentActionForm.get('payment_action').value;
    if (paymentAction === 'charge' || paymentAction === 'pending' || paymentAction === 'paid') {
      this.updateTotalAmount(paymentTerm.amount - paymentTerm.amount_paid, event.checked);
    }
  }

  public globalSelectAll($event) {
    $event.preventDefault();
    this.patchSelectAllFilter(1);

    const paymentAction = this.bulkPaymentActionForm.get('payment_action').value;

    if (paymentAction === 'charge' || paymentAction === 'pending') {
      const data = MainBaseApiService.convertFiltersForRequest(this.paymentListFilter, 'get');
      this.paymentService.getTotalAmount({...data, payment_action: paymentAction}).subscribe(result => {
          this.totalActionableAmount = result.data.total_actionable_amount ? result.data.total_actionable_amount : 0;
          // this.totalActionableRecords = result.data.total_actionable_records;
        },
      );
    }
  }

  public globalClearSelectAll($event) {
    $event.preventDefault();
    this.clearSelection();
  }

  private calculateAmountSelected(paymentTerm: PaymentTerm, paymentAction: string) {
    let actionAmount = 0;
    if (paymentAction === 'charge' || paymentAction === 'pending') {
      actionAmount = paymentTerm.amount - paymentTerm.amount_paid;
    }
    this.totalAmount += actionAmount;
  }

  public submitPaymentForm(
    paymentListFilter: PaymentListFilter,
    paymentMethod: PaymentMethodType,
    paymentAction: PaymentAction) {
    this.successMessage  = '';  //  Reset validation errors
    this.validationError = '';  //  Reset validation errors

    if (!this.selection.selected.length) {
      this.validationError = 'Please select some payments.';
      return;
    }

    paymentListFilter.terms = [];
    this.selection.selected.forEach(item => paymentListFilter.terms.push(item.id));
    paymentListFilter.expected_count = paymentListFilter.select_all ?
      this.totalResults :
      paymentListFilter.terms.length;

    const requiredRequestData = {
      terms:                 paymentListFilter.terms,
      expected_count:        paymentListFilter.expected_count,
      income_account_id:     paymentListFilter.income_account_id,
      payment_method:        paymentListFilter.payment_method,
      payment_action_method: paymentMethod,
      payment_action:        paymentAction,
    };

    let requestData: any = paymentListFilter.select_all ?
      {...paymentListFilter, ...requiredRequestData} :
      requiredRequestData;

    requestData = MainBaseApiService.convertFiltersForRequest(requestData, 'post');

    this.processingPayment++;
    this.paymentService.processTerm(requestData).pipe(finalize(() => this.processingPayment--)).subscribe(
      next => {
        this.clearSelection();
        this.fetchPayments();
        if (paymentAction === 'charge') {
          if (next.data && next.data) {
            this.successMessage = 'Payment partially successful.';
            this.toast.warning('Some transactions have failed.', 'Partial success');
          } else {
            this.successMessage = 'Payment successful.';
            this.toast.success('Payment successful', 'Payment success');
          }
        }
        if (paymentMethod === 'sepa') {
          this.toast.success('Status updated');
        }
      },
      error => {
        this.serverResponse = error.error;
        this.toast.error('Error submitting payments. Please try again later.');
      },
    );
  }

  public isCheckboxVisible(term: PaymentTerm): boolean {
    if (term.is_processing) {
      return false;
    }

    const skipPaymentStatuses = [
      'Impago Definitivo',
      'Pago Suspenso',
      'Cuenta Bloqueada',
      'Congelado',
      'CS Consulta',
    ];

    if (skipPaymentStatuses.includes(term.case.payment_status?.name)) {
      return false;
    }

    if (this.paymentListFilter.payment_method !== 'card') {
      return true;
    } else {
      return term.case.payment_registrations.length && this.hasValidCard(term) && (term.amount_paid < term.amount);
    }
  }

  hasMultipleCards(term: PaymentTerm): boolean {
    return term.case.payment_registrations?.length > 1;
  }

  public hasValidCard(term: PaymentTerm): boolean {
    return this.cardHasToken(term) && !this.cardHasExpired(term);
  }

  public cardHasToken(term: PaymentTerm): boolean {
    if (term.case.payment_registrations.length) {
      const defaultCard = term.case.payment_registrations.find(payment => payment.default);

      return defaultCard ? !!defaultCard.token : false;
    }

    return false;
  }

  public cardHasExpired(term: PaymentTerm): boolean {
    if (term.case.payment_registrations.length) {
      //  Get default card
      const defaultCard = term.case.payment_registrations.find(payment => payment.default);
      if (defaultCard) {
        //  Create expiry date moment object and set it to end of the month
        const expDateObj = DateTime.fromFormat(
          `${defaultCard.card_exp_month}${defaultCard.card_exp_year}`,
          'MMyyyy'
        ).endOf('month');
        //  Get difference in months from now to expiration date
        const expirationDate = expDateObj.diffNow('months').toObject().months;

        return expDateObj.isValid && (expirationDate < 0);
      }
    }

    return true;
  }

  public submitPayment(bulkPaymentActionForm: UntypedFormGroup) {
    if (bulkPaymentActionForm.invalid) {
      return;
    }
    this.confirmSubmit(this.paymentListFilter.payment_method, bulkPaymentActionForm.get('payment_action').value);
  }

  public billCards($event) {
    $event.preventDefault();

    return this.confirmSubmit('card', 'charge');
  }

  public confirmSubmit(paymentActionMethod: PaymentMethodType, paymentAction: PaymentAction) {

    const selectedAll = this.paymentListFilter.select_all;
    let requestAction = '';
    let message: string;

    requestAction = paymentAction === 'charge' ?
      this.translate.instant('PAYMENTS.list.bulk-actions.charge') : requestAction;
    requestAction = paymentAction === 'pending' ?
      this.translate.instant('PAYMENTS.list.bulk-actions.pending') : requestAction;
    requestAction = paymentAction === 'paid' ?
      this.translate.instant('PAYMENTS.list.bulk-actions.paid') : requestAction;
    requestAction = paymentAction === 'failed' ?
      this.translate.instant('PAYMENTS.list.bulk-actions.failed') : requestAction;

    const numberOfRecords = selectedAll ? this.totalResults : this.selection.selected.length;

    if (paymentAction === 'charge' || paymentAction === 'pending') {
      const amount = selectedAll ? this.totalActionableAmount : this.totalAmount;
      message      = this.translate.instant('PAYMENTS.list.submit-msg.charge')
        .replace('{action}', requestAction)
        .replace('{numTerms}', numberOfRecords)
        .replace('{amount}', amount.toFixed(2));
    } else {
      message = this.translate.instant('PAYMENTS.list.submit-msg.mark')
        .replace('{action}', requestAction).replace('{numTerms}', numberOfRecords);
    }

    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               message,
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.confirm'),
      confirmButtonColor: '#886ab5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.submitPaymentForm(this.paymentListFilter, paymentActionMethod, paymentAction);
      }
    });
  }

  /*************************  Change Term Date *********************************/
  public openChangeDateModal(termId, event) {
    event.preventDefault();
    const termObject = this.dataSource.data.find(data => data.id === termId);
    //  Open dialog modal
    const dialogRef  = this.dialog.open(ChangeDateModalComponent, {
      width: '50%',
      data:  {
        term: termObject,
      },
    });
    //  On close fire handle response
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchPayments();
      }
    });
  }

  public openChangeAmountModal(termId, event) {
    event.preventDefault();
    const termObject = this.dataSource.data.find(data => data.id === termId);
    //  Open dialog modal
    const dialogRef  = this.dialog.open(ChangeAmountModalComponent, {
      width: '50%',
      data:  {
        term: termObject,
      },
    });
    //  On close fire handle response
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchPayments();
      }
    });
  }

  public submitChangeDate(changeDateForm: UntypedFormGroup) {
    if (changeDateForm.invalid) {
      this.toast.error('Invalid change date form', 'Change date error.');
      return;
    }

    const data = {
      date: new Date(this.changeDateForm.value.newDate).toDateString(),
      type: this.changeDateForm.value.newDateRadio,
    };
    this.paymentService.changeDate(this.changeDateTermId, data).subscribe(
      res => {
        this.toast.success('Payment date changed successfully', 'Change date success.');
        this.fetchPayments();
      },
      error => {
        this.toast.error('Error changing date. Please try again later.', 'Change date error.');
      },
    );
  }

  public openCardModal($event, payment) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(CardInfoModalComponent, {
      width:        '60%',
      minHeight:    200,
      disableClose: true,
      panelClass:   'custom-mat-dialog',
      autoFocus:    false,
      data:         {
        amountInput: true,
        type:        'Manual charge',
        payment:     'oppwa',
        amount:      (payment.amount - payment.amount_paid).toFixed(2), // add charge amount
        caseId:      payment.case.id,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.reFetch) {
        this.fetchPayments();
      }
    });
    // }
  }

  /*************************  End Change Term Date*********************************/

  fetchPaymentMethods(): void {
    this.paymentMethodService.index().subscribe(
      res => this.paymentMethods = res.data,
      error => console.log(error),
    );
  }

  getPaymentMethodName(paymentMethodId): string {
    const paymentMethod = this.paymentMethods?.find(paymentMethodsItem => {
      return Number(paymentMethodId) === Number(paymentMethodsItem?.id);
    });

    return paymentMethod?.name;
  }

  getPaymentMethodSlug(paymentMethodId): string {
    const paymentMethod = this.paymentMethods?.find(paymentMethodsItem => {
      return Number(paymentMethodId) === Number(paymentMethodsItem?.id);
    });

    return paymentMethod?.slug;
  }

  public applyFilters() {
    this.resetPagination();
    this.resetSort();
    this.fetchPayments();
  }

  public openChangeModal(type: 'status' | 'payment_status' | 'department_assignments' | 'product_type', paymentListFilter: PaymentListFilter) {
    paymentListFilter.cases = [];
    this.selection.selected.forEach(selectedCase => paymentListFilter.cases.push(selectedCase.id));
    paymentListFilter.expected_count = paymentListFilter.select_all ?
      this.totalResults :
      paymentListFilter.cases.length;
    const data                       = {
      componentType: 'installment',
      formType:      type,
      requestFilter: paymentListFilter,
    };
    const dialogRef                  = this.dialog.open(CaseBulkActionModalComponent, {
      width:       '50%',
      minHeight:   '230px',
      hasBackdrop: false,
      data,
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public handleWireTypeOfPayment(isPaymentMethodWire: boolean): void {
    this.disableApplyBtn = isPaymentMethodWire ? true : false;
  }
}
