import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { QuillModule } from 'ngx-quill';
import { BaseSharedModule } from '../../../../_base-shared/base-shared.module';
import { AppButtonComponent } from './components/app-button/app-button.component';
import { AppDropdownComponent } from './components/app-dropdown/app-dropdown.component';
import { CardInfoModalComponent } from './components/card-info-modal/card-info-modal.component';
import { ChangeAmountModalComponent } from './components/change-amount-modal/change-amount-modal.component';
import { ChangeCreditorStatusComponent } from './components/change-creditor-status/change-creditor-status.component';
import { ChangeDateModalComponent } from './components/change-date-modal/change-date-modal.component';
import { CustomDropzoneUploadComponent } from './components/custom-dropzone-upload/custom-dropzone-upload.component';
import { ImgEditModalComponent } from './components/img-edit-modal/img-edit-modal.component';
import { PaymentErrorModalComponent } from './components/payment-error-modal/payment-error-modal.component';
import { RequestPaymentModalComponent } from './components/request-payment-modal/request-payment-modal.component';
import { ServerErrorsComponent } from './components/server-errors/server-errors.component';
import { StatusFilterComponent } from './components/status-filter/status-filter.component';
import { UploadDocumentTypeComponent } from './components/upload-document-type/upload-document-type.component';
import { VerifySignatureComponent } from './components/verify-signature/verify-signature.component';
import { GetLabelFromNamePipe } from './pipes/getLabelFromName.pipe';

@NgModule({
  imports:      [
    BaseSharedModule,
    QuillModule,
    ImageCropperModule,
    // TODO: remove unneeded imports after components moved
    MatTooltipModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    NgxDropzoneModule,
    NgxMatSelectSearchModule,
    MatTabsModule,
    DragDropModule,
    MatMenuModule
  ],
  declarations: [
    ServerErrorsComponent,
    GetLabelFromNamePipe,
    StatusFilterComponent,
    CardInfoModalComponent,
    ChangeDateModalComponent,
    ChangeAmountModalComponent,
    UploadDocumentTypeComponent,
    CustomDropzoneUploadComponent,
    RequestPaymentModalComponent,
    VerifySignatureComponent,
    ChangeCreditorStatusComponent,
    PaymentErrorModalComponent,
    ImgEditModalComponent,
    AppDropdownComponent,
    AppButtonComponent,
  ],
  exports:      [
    BaseSharedModule,
    QuillModule,
    // Material Imports
    MatNativeDateModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    MatProgressBarModule,
    MatTabsModule,
    MatMenuModule,
    // End material import
    NgxDropzoneModule,
    ServerErrorsComponent,
    StatusFilterComponent, // @deprecate
    // TODO: move
    ChangeDateModalComponent,
    ChangeAmountModalComponent,
    UploadDocumentTypeComponent,
    GetLabelFromNamePipe,
    AppDropdownComponent,
    AppButtonComponent,
    // End move
  ],
})

export class SharedModule {
}
