import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AlertComponent } from './components/alert/alert.component';
import { AppInputComponent } from './components/app-input/app-input.component';
import { EmptyDatasetComponent } from './components/empty-dataset/empty-dataset.component';
import { HtmlShadowDomComponent } from './components/html-shadow-dom/html-shadow-dom.component';
import { InputValidationErrorsComponent } from './components/input-validation-errors/input-validation-errors.component';
import { ServerResponseComponent } from './components/server-response/server-response.component';
import { SpinnerBtnComponent } from './components/spinner-btn/spinner-btn.component';
import { DotCaseToSnakePipe } from './pipes/dot-case-to-snake.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SnakeCaseToCamelPipe } from './pipes/snake-case-to-camel.pipe';
import { ToDateObjectPipe } from './pipes/to-date-object.pipe';

@NgModule({
  imports:      [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    // Mat Modules
    MatListModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
  ],
  declarations: [
    // Pipes
    ToDateObjectPipe,
    SafePipe,
    DotCaseToSnakePipe,
    SnakeCaseToCamelPipe,
    // Components
    AlertComponent,
    ServerResponseComponent,
    SpinnerBtnComponent,
    InputValidationErrorsComponent,
    AppInputComponent,
    HtmlShadowDomComponent,
    EmptyDatasetComponent,
  ],
  exports:      [
    // Modules
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    // Mat Modules
    MatListModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    // Pipes
    ToDateObjectPipe,
    SafePipe,
    DotCaseToSnakePipe,
    SnakeCaseToCamelPipe,
    // Components
    ServerResponseComponent,
    SpinnerBtnComponent,
    AppInputComponent,
    HtmlShadowDomComponent,
    EmptyDatasetComponent,
  ],
})
export class BaseSharedModule {
}
